import { Layout, Title } from "@components";
import { RouteLocation } from "@interfaces";
import React from "react";

interface Props {
    location: RouteLocation;
}

const Homepage = ({ location }: Props): JSX.Element => (
    <Layout lang="en" title={`Home`} location={location}>
        <main>
            <div className="container-lg text-center">
                <Title tag="h1">Hello CV Generator</Title>
                <p>
                    This is the frontend of the CV generator. To view the team
                    pages, copy-paste the <b>unique team slug</b> in the url.
                </p>
            </div>
        </main>
    </Layout>
);
export default Homepage;
